<template>
  <div class="BigBox">
    <!-- 飞行器降落 -->
    <div class="Aircraft">
      <img src="../../assets/HomePage/Aircraft.png" alt="" />
    </div>
    <!-- VR -->
    <div class="VR_V">
      <!-- iframe嵌入VR全景 -->
      <iframe :src="VR_List[0]" height="100%" width="100%" frameborder="0" allowfullscreen="true" ref="obj">
      </iframe>
      <!-- 导航+推拉门 -->
      <div class="NavigationBox">
        <div @mouseenter="mouseenter()" @mouseleave="mouseleave()" @click="Back()" class="NavigationBack">
          <div class="NavigationBackText"><span>首</span></div>
          <div class="NavigationBackImg">
            <img src="../../assets/VRVisit/Back.png" alt="" />
          </div>
        </div>
        <div @click="Sliding_Door()" class="Sliding_Door">
          <div class="Sliding_DoorImgBox">
            <img id="Door" src="../../assets/VRVisit/Sliding_Door.png" alt="" />
          </div>
        </div>
      </div>
      <!-- 详情 -->
      <div class="Particulars">
        <div class="container">
          <div class="BigBox">
            <div @click="JG_PLList()" class="BoxOne">
              <div class="BoxOne_Title">
                <div style="
                    width: 50%;
                    height: 100%;
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                  ">
                  <img style="width: 100%; height: 100%" src="../../assets/VRVisit/JG_title_One.png" alt="" />
                </div>
              </div>
              <div class="BoxOne_img">
                <img :src="JGOneList.thumbnail?.resourceUrl" alt="" style="width: 100%; height: 100%" />
              </div>
              <div class="BoxOne_Text">
                <div v-html="JGOneList.summary" style="margin: 2% 1%"></div>
              </div>
            </div>
            <div class="BoxTwo" >
              <div class="BoxTwo_Title">
                <div style="
                    width: 50%;
                    height: 90%;
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                  ">
                  <img style="width: 100%;height: 95%;border-bottom: 3px solid rgb(94, 47, 47);"
                    src="../../assets/VRVisit/JG_title_Two.png" alt="" />
                </div>
              </div>
              <div class="BoxTwo_Text">
                <ul v-for="(item, index) in JGTwoList" :key="index">
                  <li v-html="item.summary" style="width: 100%; margin: 5px 0"></li>
                  <li style="text-indent: 0em; width: 100%; height: 69%">
                    <img style="width: 100%; height: 100%" :src="item.thumbnail?.resourceUrl" alt="图片加载失败" @click="HistoricBuildings()" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="BoxThere">
              <div class="BoxThere_Title">
                <div style="
                    width: 40%;
                    height: 90%;
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                  ">
                  <img style="
                      width: 100%;
                      height: 95%;
                      border-bottom: 3px solid rgb(94, 47, 47);
                    " src="../../assets/VRVisit/JG_title_There.png" alt="" />
                </div>
              </div>
              <div class="BoxThere_Text">
                <div style="
                    overflow-y: scroll;
                    overflow-x: hidden;
                    width: 100%;
                    height: 90%;
                  ">
                  以影像为本体的一种记忆承载形式当今时代，有一种东西，正在变成那些将要消失的传统生活和文化景观的一个安全的贮藏地，一个可靠的安放地——是什么东西呢？那就是影像。当我们要去保护和传承一种传统文化现象时，很多时候，我们发现唯一的、最有效、最可靠的手段，就是诉诸影像。
                </div>
              </div>
              <div class="BoxThere_Img">
                <ul style="
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    height: 35%;
                    margin: 8px 0;
                  ">
                  <li v-for="(item, index) in JGThereList" @click="videolog()" style="width: 48%; height: 100%" :key="index">
                    <img style="width: 100%; height: 100%" :src="item.thumbnail.resourceUrl" alt="图片加载失败" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="BoxFour" @click="ShadowPlay()">
              <div class="BoxFour_Title">
                <div style="
                    width: 65%;
                    height: 90%;
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                  ">
                  <img style="
                      width: 100%;
                      height: 95%;
                      border-bottom: 3px solid rgb(94, 47, 47);
                    " src="../../assets/VRVisit/JG_title_Four.png" alt="" />
                </div>
              </div>
              <div class="BoxFour_Text">
                靖港古镇范围内非遗文化资源十分丰富，以民间技艺、民间艺术、民间美食为主，现有非物质文化遗产代表性项目11个
              </div>
              <ul class="BoxFour_Img">
                <li  v-for="(item, index) in JGFourListImg" :key="index" style="width: 98%; height: 20%; margin: 5px 0"  >
                  <img style="width: 100%; height: 100%" :src="item" alt="图片加载失败" />
                </li>
              </ul>
            </div>
            <div class="BoxFive">
              <div class="BoxFive_Title">
                <div style="
                    width: 50%;
                    height: 90%;
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                  ">
                  <img style="
                      width: 100%;
                      height: 95%;
                      border-bottom: 3px solid rgb(94, 47, 47);
                    " src="../../assets/VRVisit/JG_title_Five.png" alt="" />
                </div>
              </div>
              <div class="BoxFive_Img">
                <img src="http://8.134.93.240/img/%E6%99%AF%E8%A7%82%E5%9F%BA%E5%9B%A0One.e1b78d47.png" alt="图片加载失败" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 详情结束 -->
      <!-- 关闭详情 -->
      <div class="Close_Particulars">
        <img src="../../assets/VRVisit/Close.gif" @click="Close_Particulars()" alt="" />
      </div>
      <!-- 底栏 -->
      <div class="bottom_Table">
        <li v-for="(item, index) in DataList" @click="change(index)" :key="index">
          <span :class="{ actived: active == index }">{{ item }}</span>
        </li>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { log } from 'console';
export default {
  data() {
    return {
      active: 0,
      DataList: ["靖港", "铜官", "新康", "乔口", "书堂山"],
      VR_List: [
        "https://www.720yun.com/vr/aa826xi8ces",
        "https://720yun.com/vr/a3025xikxen",
        "https://720yun.com/vr/e0b2dxikxeg",
        "https://720yun.com/vr/47120xikxl6",
        "https://720yun.com/vr/78521xikxlr",
      ],
      JGLists: "",
      JGOneList: {},
      JGTwoList: "",
      JGThereList: [],
      JGFourList: "",
      JGFourListImg:[]
    };
  },
  mounted() {
    this.setTimeout(), this.ScParticulars();
  },
  created() {
    this.postJGList();
  },
  methods: {
    // 获取数据
    postJGList() {
      var axios = require("axios");
      var data = JSON.stringify({
        pageModuleCode: "gz_jinggang",
      });

      var config = {
        method: "post",
        url:this.$Schttp+"/vtp/app/contentPublish/vrIndex",

        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((res) => {
          let JGLists = res.data.data;
          this.JGOneList = JGLists.townInfo;
          this.JGTwoList = JGLists.builds;
          this.JGThereList = JGLists.graphys;
          this.JGFourList = JGLists.heritages;
          for (let i = 0; i < this.JGFourList.length; i++) {
            if(this.JGFourList[i].thumbnail != null){
              this.JGFourListImg.push(this.JGFourList[i].thumbnail.resourceUrl)
            }
          }   
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 跳转影像志
    videolog(){
      this.$router.push('/jG_VideoLog')
    },
    // 跳转至靖港详情主页
    JG_PLList() {
      this.$router.push({
        name: "jG_ParticularsList",
        query:{id:this.JGOneList.id} 
      });
    },
    // 跳转至靖港皮影
    ShadowPlay() {
      this.$router.push("/shadowPlay");
    },
    // 跳转至历史建筑     
    HistoricBuildings() {
      this.$router.push("/historicBuildings");
    },
    // 推拉门
    Sliding_Door() {
      $(".Particulars").animate({ width: "toggle" }, 500);
      if ($(".Close_Particulars").is(":hidden")) {
        $(".Close_Particulars").show(); //如果元素为隐藏,则将它显现
      } else {
        $(".Close_Particulars").hide(); //如果元素为显现,则将其隐藏
      }
    },
    Close_Particulars() {
      $(".Particulars").animate({ width: "toggle" }, 500);
      $(".Close_Particulars").css("display", "none");
    },
    // 首次加载打开详情
    ScParticulars() {
      setTimeout(function () {
        if ($(".Particulars").is(":hidden")) {
          $(".Particulars").animate({ width: "toggle" }, 500); //如果元素为隐藏,则将它显现
          $(".Close_Particulars").css("display", "block");
        } else {
          //如果元素为显现,则将其隐藏
        }
      }, 6500);
    },
    // 返回首页
    Back() {
      this.$router.push({ path: "/" });
    },
    // 返回首页移入移出
    mouseenter() {
      $(".NavigationBackImg").css("display", "none");
      $(".NavigationBackText").css("display", "block");
    },
    mouseleave() {
      $(".NavigationBackImg").css("display", "block");
      $(".NavigationBackText").css("display", "none");
    },
    /*切换需要高亮的按钮*/
    change(index) {
      this.active = index;
      this.VR_List[0] = this.VR_List[index];
      if (index == 0) {
        this.VR_List[0] = "https://720yun.com/t/24vk6l1mzpy";
      }
    },
    // 飞行器落地
    setTimeout() {
      $(".Aircraft").animate({ top: "40%" }, 6200, () => {
        $(".Aircraft").remove();
      });
    },
  },
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: "思源黑体"; // 自定义的名字
  src: url("../../ZiTi/normal.otf"); //下载的包
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.BigBox {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .VR_V {
    width: 100%;
    height: 100%;
  }

  .bottom_Table {
    width: 50%;
    height: 5%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 91%;
    left: 25%;
    display: flex;
    color: white;

    li {
      cursor: pointer;
      width: 25%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }
  }

  .actived {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 23px;
    border-bottom: 3px solid yellow;
  }

  .Aircraft {
    position: absolute;
    width: 150px;
    height: 150px;
    left: 47%;
    top: -40%;
    display: block;

    img {
      width: 100%;
      height: 100%;
    }
  }

  // 返回+推拉门按钮
  .NavigationBox {
    width: 30px;
    height: 80px;
    position: fixed;
    right: 1%;
    top: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .NavigationBack {
      width: 30px;
      height: 30px;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;

      .NavigationBackText {
        height: 100%;
        width: 100%;
        background-color: black;
        border-radius: 25px;
        color: white;
        display: none;
        cursor: pointer;

        span {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .NavigationBackImg {
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 25px;
        cursor: pointer;
        display: block;

        img {
          width: 100%;
          height: 100%;
          transform: scale(0.7);
        }
      }
    }

    .Sliding_Door {
      width: 30px;
      height: 30px;
      border-radius: 25px;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .Sliding_DoorImgBox {
        width: 100%;
        height: 100%;

        #Door {
          width: 100%;
          height: 100%;
          /* 溢出隐藏 */
          animation-name: scaleDraw;
          /*关键帧名称*/
          animation-timing-function: ease-in-out;
          /*动画的速度曲线*/
          animation-iteration-count: infinite;
          /*动画播放的次数*/
          animation-duration: 1.5s;

          /*动画所花费的时间*/
          @keyframes scaleDraw {

            /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
            0% {
              transform: scale(0.8);
              /*开始为原始大小*/
            }

            100% {
              transform: scale(1.5);
            }
          }
        }
      }
    }
  }

  // 结束
  // 详情
  .Particulars {
    width: 90%;
    height: 82%;
    position: absolute;
    top: 8.5%;
    right: 4%;
    display: none;

    .container {
      height: 100%;
      width: 100%;
    }

    .BigBox {
      width: 100%;
      height: 100%;
      // text-align:center;
      display: flex;
      justify-content: space-between;

      .BoxOne {
        width: 19.5%;
        height: 100%;
        background-image: url("../../assets/VRVisit/JG_background.png");
        background-size: 100% 100%;
        
        .BoxOne_Title {
          width: 100%;
          height: 7%;
          display: flex;
          align-items: center;
        }

        .BoxOne_img {
          width: 90%;
          height: 18%;
          margin: 0 auto;
          cursor: pointer;
        }

        .BoxOne_Text {
          width: 95%;
          height: 69%;
          margin: 6px 10px;
          overflow-y: scroll;
          overflow-x: hidden;
          font-size: 14px;
          text-indent: 2em;
          line-height: 20px;
          font-family: "思源黑体";
        }
      }

      .BoxTwo {
        width: 19.5%;
        height: 100%;
        background-image: url("../../assets/VRVisit/JG_background.png");
        background-size: 100% 100%;

        .BoxTwo_Title {
          width: 100%;
          height: 9%;
          display: flex;
          align-items: center;
        }

        .BoxTwo_Text {
          overflow-y: scroll;
          overflow-x: hidden;
          width: 95%;
          height: 85.5%;
          margin: 6px 10px;

          ul {
            width: 100%;
            height: 35%;
            margin: 5px 0;
            font-size: 14px;
            text-indent: 2em;
            line-height: 23px;
            font-family: "思源黑体";

            li {
              overflow: hidden; //超出隐藏
              text-overflow: ellipsis; //超出文本设置为...
              display: -webkit-box; //将div1转换为盒子模型
              -webkit-line-clamp: 2; //设置div1的文本为2行
              -webkit-box-orient: vertical; //从顶部向底部垂直布置子元素
              /*white-space: initial;*/
              img{
                cursor: pointer;
              }
            }
          }
        }
      }

      .BoxThere {
        width: 19.5%;
        height: 100%;
        background-image: url("../../assets/VRVisit/JG_background.png");
        background-size: 100% 100%;

        .BoxThere_Title {
          width: 100%;
          height: 9%;
          display: flex;
          align-items: center;
        }

        .BoxThere_Text {
          width: 95%;
          height: 15%;
          margin: 6px 10px;
          font-size: 14px;
          text-indent: 2em;
          line-height: 20px;
          font-family: "思源黑体";
          display: flex;
          align-items: center;
        }

        .BoxThere_Img {
          overflow-y: scroll;
          overflow-x: hidden;
          width: 95%;
          height: 59%;
          margin: 0 10px;
          img{cursor: pointer;}
        }
      }

      .BoxFour {
        width: 19.5%;
        height: 100%;
        background-image: url("../../assets/VRVisit/JG_background.png");
        background-size: 100% 100%;

        .BoxFour_Title {
          width: 100%;
          height: 9%;
          display: flex;
          align-items: center;
        }

        .BoxFour_Text {
          width: 90%;
          margin: 8px auto;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          text-indent: 2em;
          line-height: 20px;
          font-family: "思源黑体";
        }

        .BoxFour_Img {
          width: 94%;
          height: 69%;
          margin: 0 12px;
          overflow-y: scroll;
          overflow-x: hidden;
        }
      }

      .BoxFive {
        width: 19.5%;
        height: 100%;
        background-image: url("../../assets/VRVisit/JG_background.png");
        background-size: 100% 100%;

        .BoxFive_Title {
          width: 100%;
          height: 9%;
          display: flex;
          align-items: center;
        }

        .BoxFive_Img {
          width: 90%;
          height: 87%;
          margin: 10px auto;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .Close_Particulars {
    position: absolute;
    top: 30%;
    left: 0.2%;
    width: 5%;
    height: 38%;
    display: none;

    img {
      width: 110%;
      height: 100%;
      cursor: pointer;
      transform: scale(1.3);
    }
  }

  // 滚动条设置
  @remvw: 1920 /100vw;

  ::-webkit-scrollbar {
    width: 7 / @remvw;
    /*对垂直流动条有效*/
    height: 7 / @remvw;
    /*对水平流动条有效*/
  }

  /*定义外层滚动槽*/
  ::-webkit-scrollbar-track-piece {
    border-radius: 6 / @remvw;
  }

  /*定义内层 滑块颜色、内阴影及圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 6 / @remvw;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #8b8b8b;
    opacity: 0.3;
  }

  /*定义两端按钮的样式*/
  ::-webkit-scrollbar-button {
    display: none;
  }

  /*定义边角样式*/
  ::-webkit-scrollbar-corner {
    display: none;
  }
}
</style>
